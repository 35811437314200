import { useState } from "react";
import SimplePagination from "@/component/pagination";
import { GetAllBaseItem } from "@/services/base_item";
import { RedSpinner } from "@/component/loading/spinner";
import RenderListItem from "./renderItem";
import { useSelector } from "react-redux";
import { RootState } from "@/redux";

const limit = 40;

export default function ContentHomePage() {
  const cartVisible = useSelector((state: RootState) => state.cartVisibility);
  const keyword = useSelector((state: RootState) => state.search);

  const [currentPage, setCurrentPage] = useState(1);
  const responseItem = GetAllBaseItem({
    page: currentPage,
    perPage: limit,
    search: keyword,
  });

  return (
    <div>
      {responseItem.isLoading ? (
        <RedSpinner />
      ) : (
        <div>
          <ul
            role="list"
            className={`grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-8 ${
              cartVisible ? "sm:w-3/4" : "w-full"
            }`}
          >
            {RenderListItem({ arr: responseItem.data.data })}
          </ul>
          <div className="py-4">
            <SimplePagination
              page={currentPage}
              totalItem={responseItem.data.total}
              limit={limit}
              changePageFunc={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
}
