import { RootState } from "@/redux";
import { removeItemCartById } from "@/redux/reducers/cart";
import { changeCartVisible } from "@/redux/reducers/cart_visibility";
import { addItems } from "@/redux/reducers/items";
import { Transition } from "@headlessui/react";
import {
  ShoppingCartIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Coin from "../../../public/coin.svg";

export default function CartDrawer() {
  const cart = useSelector((state: RootState) => state.cart);
  const cartVisible = useSelector((state: RootState) => state.cartVisibility);
  const items = useSelector((state: RootState) => state.items);

  const dp = useDispatch();

  const [price, setPrice] = useState(0);

  const handleChangeVisible = () => {
    dp(changeCartVisible(!cartVisible));
  };

  useEffect(() => {
    let price = 0;
    cart.map((item) => {
      price = price + item.selling_price;
    });
    setPrice(price);
  }, [cart]);

  return (
    <Transition
      show={cartVisible}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0 translate-x-full"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-full"
      className={`w-full top-0 fixed right-0 sm:top-24 sm:w-1/4 sm:z-0 h-full bg-gray-800 rounded-md p-4`}
    >
      <div className="flex flex-col bg-gray-800 shadow-xl rounded-md relative h-full">
        <div className="flex-1 py-3">
          <div className="flex items-start justify-between">
            <h3 className="text-base font-medium text-gray-100 capitalize ">
              Shopping cart
            </h3>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                onClick={() => handleChangeVisible()}
              >
                <span className="sr-only">Close panel</span>
                <XCircleIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>

          <div className="mt-4 h-full">
            {cart.length === 0 ? (
              <div className="text-gray-600 font-medium text-sm text-center mt-10">
                <ShoppingCartIcon className="w-12 text-gray-900 mx-auto" />
                Cart is empty
              </div>
            ) : (
              <ul
                role="list"
                className="h-3/6 divide-y divide-gray-200 overflow-scroll my-2"
              >
                {cart.map((product) => (
                  <li key={product.id} className="py-3 flex">
                    <div className="h-24 w-24 md:mx-auto">
                      <Image
                        src={`https://community.akamai.steamstatic.com/economy/image/${product.base_items.image}`}
                        width={80}
                        height={80}
                        alt="item"
                      />
                    </div>

                    <div className="ml-4 flex flex-1 flex-col">
                      <div>
                        <div className="justify-between text-base font-medium text-gray-100 gap-2 xl:flex">
                          <div>
                            <h3 className="text-base font-medium text-gray-200">
                              <a href={""}>{product.base_items.name}</a>
                            </h3>
                            <p className="-mt-1 text-xs text-gray-400">
                              {product.base_items.type}
                            </p>
                          </div>

                          <p className=" flex items-center gap-1 font-semibold text-white text-base">
                            <Image
                              src={Coin}
                              alt="weapon"
                              width={15}
                              height={15}
                            />
                            {product.selling_price}
                          </p>
                        </div>
                      </div>
                      <div className="mt-2 xl:mt-5">
                        {/* <p className="text-gray-500">
                                      Qty {product.}
                                    </p> */}

                        <div className="flex">
                          <button
                            onClick={() => {
                              dp(removeItemCartById({ id: product.id }));
                            }}
                            type="button"
                            className="font-medium text-sm bg-gray-600 p-1.5 rounded-md text-slate-200 hover:bg-red-900 hover:ease-in-out duration-300"
                          >
                            <TrashIcon className="w-5 text-slate-200" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-3 absolute w-full bottom-24">
          <div className="flex justify-between text-base font-medium text-slate-200">
            <p>Subtotal</p>
            <p className="ml-4 flex items-center gap-1 font-semibold text-white text-base">
              <Image src={Coin} alt="weapon" width={15} height={15} />
              {cart.length === 0 ? 0 : price}
            </p>
          </div>
          <div className="mt-2">
            <a
              href="#"
              className="flex items-center justify-center rounded-md border border-transparent bg-gray-700 py-3 text-base font-medium text-gray-500 shadow-sm capitalize hover:bg-rose-500 ease-in-out duration-300 hover:text-white"
            >
              Check out
            </a>
          </div>
        </div>
      </div>
    </Transition>
  );
}
