import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid";
import React, { Dispatch, SetStateAction } from "react";

interface IPagination {
  limit: number;
  page: number;
  totalItem: number;
  changePageFunc: Dispatch<SetStateAction<number>>;
}

function SimplePagination({
  limit,
  page,
  totalItem,
  changePageFunc,
}: IPagination) {
  const totalPages = Math.ceil(totalItem / limit);
  const maxVisiblePages = 5; // Adjust the number of visible page links here

  const getPageLinks = () => {
    const pageLinks = [];
    if (totalPages > 1) {
      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          pageLinks.push(createPageLink(i));
        }
      } else {
        const halfVisiblePages = Math.floor(maxVisiblePages / 2);
        if (page <= halfVisiblePages) {
          for (let i = 1; i <= maxVisiblePages - 1; i++) {
            pageLinks.push(createPageLink(i));
          }
          pageLinks.push(createEllipsis());
          pageLinks.push(createPageLink(totalPages));
        } else if (page > totalPages - halfVisiblePages) {
          pageLinks.push(createPageLink(1));
          pageLinks.push(createEllipsis());
          for (let i = totalPages - maxVisiblePages + 3; i <= totalPages; i++) {
            pageLinks.push(createPageLink(i));
          }
        } else {
          pageLinks.push(createPageLink(1));
          pageLinks.push(createEllipsis());
          for (
            let i = page - halfVisiblePages + 1;
            i <= page + halfVisiblePages - 1;
            i++
          ) {
            pageLinks.push(createPageLink(i));
          }
          pageLinks.push(createEllipsis());
          pageLinks.push(createPageLink(totalPages));
        }
      }
    }
    return pageLinks;
  };

  const createPageLink = (pageNumber: any) => {
    const isCurrentPage = pageNumber === page;
    const linkClass = isCurrentPage
      ? "border-indigo-500 text-indigo-600"
      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300";

    return (
      <a
        key={pageNumber}
        href="#"
        className={`inline-flex items-center border-t-2 ${linkClass} px-4 pt-4 text-sm font-medium`}
        aria-current={isCurrentPage ? "page" : undefined}
        onClick={() => {
          changePageFunc(pageNumber);
        }}
      >
        {pageNumber}
      </a>
    );
  };

  const createEllipsis = () => {
    return (
      <span
        key="ellipsis"
        className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium"
      >
        ...
      </span>
    );
  };

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <a
          href="#"
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          onClick={() => {
            changePageFunc(Math.max(page - 1, 1));
          }}
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Previous
        </a>
      </div>
      <div className="hidden md:-mt-px md:flex">{getPageLinks()}</div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          href="#"
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          onClick={() => {
            changePageFunc(Math.min(page + 1, totalPages));
          }}
        >
          Next
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </a>
      </div>
    </nav>
  );
}

export default SimplePagination;
