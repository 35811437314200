import { IBaseItems } from "@/interfaces/item";
import { commonFetcher } from "@/utils";
import { time } from "console";
import useSWR from "swr";

export interface Root {
  data: IBaseItems[];
  total: number;
}

export interface Tag {
  category: string;
  internal_name: string;
  localized_tag_name: string;
  localized_category_name: string;
  color?: string;
}

export interface Paging {
  page: number;
  perPage: number;
  search: string;
}

export function GetAllBaseItem({ page, perPage, search }: Paging) {
  // setTimeout(() => {}, 1000);
  const { data, error, isLoading } = useSWR(
    `/base-items?page=${page}&perPage=${perPage}&search=${search}`,
    commonFetcher
  );

  let res = data as Root;

  return {
    data: res,
    isLoading,
    isError: error,
  };
}
