import { IBaseItems } from "@/interfaces/item";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import Coin from "../../../public/coin.svg";

export default function renderListItem({ arr }: { arr: IBaseItems[] }) {
  return arr.map((item: IBaseItems) => (
    <li
      key={item.id}
      className=" divide-gray-200 w-full rounded-lg bg-gray-700 text-left shadow border-2 border-gray-800 hover:bg-gray-700 hover:border-rose-800 transition-all cursor-pointer"
    >
      <Link href={`items/${item.id}`}>
        <div className="flex flex-1 flex-col px-3 py-2 justify-between">
          {/* Header */}
          <div className="flex justify-between mb-1">
            <h4 className="text-xs font-medium text-gray-200 ">
              {item.condition || <div className="invisible">null</div>}
            </h4>
          </div>
          {/* Header */}

          {/* IMG */}
          <div className="flex-shrink-0 relative h-36 sm:h-32">
            <Image
              className="flex-shrink-0 mx-auto"
              src={`https://community.akamai.steamstatic.com/economy/image/${
                item.image || item.thumb_image
              }`}
              alt={item.name}
              fill={true}
              loading="eager"
              style={{ objectFit: "contain" }}
            />
          </div>
          {/* IMG */}

          {/* Description */}
          <div className="">
            <h3 className="mt-2 text-xs font-medium text-gray-500 truncate">
              {item.stattrak ? "StatTrak™ " : ""}
              {item.type}
            </h3>

            <h3 className="text-sm font-medium text-gray-200 truncate mb-1.5 -mt-0.5">
              {item.name}
            </h3>
            <span className="font-semibold flex items-center gap-1 text-white text-base">
              <Image src={Coin} alt="weapon" width={15} height={15} />
              {item.steam_price}
            </span>
          </div>
          {/* Description */}
        </div>
      </Link>
    </li>
  ));
}
