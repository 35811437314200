import CartDrawer from "@/component/cart_drawer";
import { Fragment } from "react";
import ContentHomePage from "./content";

function HomePage() {
  return (
    <Fragment>
      <CartDrawer />
      <ContentHomePage />
    </Fragment>
  );
}

export default HomePage;
